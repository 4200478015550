/*============= Icons Css Style ============*/
.icon-box {
	li {
		color: $gray-700;
		padding: 0.8em 0;
		position: relative;
		&:hover [class^="fa-"],
		&:hover .mat-icon,
		&:hover span i {
			font-size: 2rem;
			color: $blue;
			margin-top: -5px;
		}
		&:hover span {
			color: $blue;
		}
	}
	span,
	.icon-title {
		font-size: 16px;
		margin-left: 35px;
	}
	[class^="fa-"],
	[class*="fa-"],
	span i,
	.mat-icon {
		margin: 0 15px 0 0;
		transition: all 0.5s ease 0s;
      position: absolute;
      top: 5px;
	}
}
.mat-list,
.icon-wrapper {
	li {
		width: 25%;
		float: left;
	}
}
.glyph-wrapper {
	> div {
		width: 14%;
		text-align: center;
		display: inline-block;
	}
	.mega {
		font-size: 2rem;
		padding: 15px;
		display: inline-block;
	}
	i {
		margin: 0;
	}
}
.glyph-item:hover {
	background-color: $blue;
	color: $white;
}
/*======== Icons Responsive Style ========*/
@media (max-width: 767px) {
	.glyph-wrapper {
		> div {
			width: auto;
		}
	}
}
@media (max-width: 1459px) {
	.mat-list li,
	.icon-wrapper li {
		width: 50%;
	}
}
@media (max-width: 645px) {
	.mat-list li,
	.icon-wrapper li {
		width: 100%;
	}
}
/*========== All Icons Common ===========*/
.font-icon-wrapper,.material-icon,.linea-icon-wrapper {
   .icon-wrap {
      height:48px;
      transition: all .4s ease;
      &:hover{
         .icon,.icon-name{ 
            color:$primary;
         }
         .icon{
            transform: scale(1.5);
            transition: all .4s ease;
         }
      }      
   }
}   