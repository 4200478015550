/*========== Form Elements Css Style ============*/
.form-group label {
	font-weight: 400;
	margin: 0 0 10px;
	color: $from-label-color;
}
.success-addon {
	background: $green;
}
.primary-addon {
	background: $blue;
	color: $white;
}
.form-control.active {
	border-color: $blue;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba($blue, 0.6);
	outline: 0 none;
}
.input-square {
	border-radius: $input-square;
}
.input-rounded {
	border-radius: $input-rounded;
}
.steps-indicator {
	margin-bottom: 2rem !important;
}
.steps-indicator {
	display: flex;
	justify-content: center;
	margin-top: 3rem;
	text-align: center;

	li {
		width: 33%;
		list-style: none;
		position: relative;
		&.current {
			a {
				&:before {
					background-color: #339933;
				}
			}
		}
		a {
			line-height: 14px;
			font-size: 14px;
			text-decoration: none;
			text-transform: uppercase;
			text-align: center;
			font-weight: bold;
			transition: 0.25s;
			cursor: pointer;
			position: relative;
			&:before {
				position: absolute;
				top: -14px;
				left: calc(50% - 7px);
				width: 14px;
				height: 14px;
				content: "";
				text-align: center;
				vertical-align: middle;
				line-height: 14px;
				transition: 0.25s;
				border-radius: 100%;
				background-color: #e6e6e6;
			}
		}
		&:after {
			background-color: #e6e6e6;
			content: "";
			position: absolute;
			height: 1px;
			width: calc(100% - 14px);
			top: -20px;
			left: calc(50% + 7px);
		}
	}
}
.steps-indicator li:last-child:after {
	content: none;
}

