/*=========== 4.0 Elements =============*/

@import "elements/effect";
@import "elements/slick";
@import "elements/session";
@import "elements/user";
@import "elements/pricing";
@import "elements/blog";
@import "elements/gallery";
@import "elements/ecommerce";
@import "elements/icons";
@import "elements/datepicker";
@import "elements/calendar";
@import "elements/grid";
@import "elements/testimonial";
@import "elements/inbox";
@import "elements/editor";
@import "elements/sidebar-option";
@import "elements/animate";
@import "elements/tour";
@import "elements/courses";
@import "elements/video-player";
@import "elements/pages";