/*========== Dark Theme ==========*/
.app-dark {
	background-color: $dark-bg-container;
	.page-info a {
		color: $dark-bg-color;
	}
	.main-contents,
	code {
		background-color: $dark-bg-container;
	}
	.inbox-wrapper,
	.chat-wrapper {
		background-color: $dark-bg;
	}
	.sidebar-user-menu .sidebar-user-menu .sidebar-user-info-wrap .sidebar-user-name,
	.sidebar-user-name,
	.sidebar-user-role,
	.navigation > ul li a,
	.navbar-brand,
	.base-card h2,
	.base-card h4,
	.base-card .dash-icon i,
	.chat-content.receiver,
	.dropdown-item,
	.user-profile-wrapper .bottom-block .thumb-content a,
	.user-profile-wrapper .bottom-block .user-account-list li:nth-child(2n) a,
	.btn,
	.course-wrap .filters-wrap .navbar-light .navbar-nav .nav-link,
	.course-wrap .filters-wrap .navbar-light .navbar-nav .nav-link:hover,
	.course-wrap .filters-wrap .navbar-light .navbar-nav .nav-link:focus,
	.course-wrap .course-faq-wrap .accordion button > span,
	.chat-window .msg-box,
	.courses-payment .nav-pills .nav-link,
	.feedback-wrap .nav-pills .nav-link {
		color: $dark-bg-color;
	}
	.btn-secondary,
   .bg-secondary,
   a:not([href]):not([tabindex]).btn-secondary {
		color: $body-color;
	}
	.btn-dark {
		color: $dark-bg;
		background-color: $dark-bg-color;
	}
	.text-dark,a.text-dark:hover, a.text-dark:focus {
		color: $dark-bg-color !important;
	}
	.bg-white {
		background-color: $dark-bg !important;
		color: $dark-bg-color !important;
	}
	.border,
	.border-left,
	.border-right,
	.border-top,
	.border-bottom,
	hr {
		border-color: $dark-border-color !important;
	}
	.bg-light,
	.dropdown-item:hover,
	.dropdown-item:focus {
		color: $dark-bg-color !important;
		background-color: #616161 !important;
	}
	.sidebar-panel::before {
		background-color: rgba($dark-bg, 0.87);
   }
   &.warning, &.success,&.primary{
      .icon-sidebar .navigation > ul li ul.sub-menu{
         background-color: $dark-bg !important;
      }
   }
	.tour-step-navigation .btn {
		color: $dark-bg-color;
		border-color: $dark-border-color;
	} //bottom
	.bs-popover-top .arrow::before,
	.bs-popover-auto[x-placement^="top"] .arrow::before {
		border-top-color: $dark-border-color;
	}
	.bs-popover-top .arrow::after,
	.bs-popover-auto[x-placement^="top"] .arrow::after {
		border-top-color: $dark-bg !important;
	} //top
	.bs-popover-bottom .arrow::before,
	.bs-popover-auto[x-placement^="bottom"] .arrow::before,
	.popover.bs-popover-bottom .popover-header::before,
	.popover.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
	.popover.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
		border-bottom-color: $dark-border-color;
	}
	.bs-popover-bottom .arrow::after,
	.bs-popover-auto[x-placement^="bottom"] .arrow::after {
		border-bottom-color: $dark-bg !important;
	} //left
	.bs-popover-right .arrow::before,
	.bs-popover-auto[x-placement^="right"] .arrow::before,
	.course-item .course-hover-item::before {
		border-right-color: $dark-border-color;
	}
	.bs-popover-right .arrow::after,
	.bs-popover-auto[x-placement^="right"] .arrow::after,
	.course-item .course-hover-item::after {
		border-right-color: $dark-bg !important;
	} //Right
	.bs-popover-left .arrow::before,
	.bs-popover-auto[x-placement^="left"] .arrow::before {
		border-left-color: $dark-border-color;
	}
	.bs-popover-left .arrow::after,
	.bs-popover-auto[x-placement^="left"] .arrow::after {
		border-left-color: $dark-bg !important;
	}
	.ql-snow .ql-stroke,
	.ql-snow .ql-fill,
	.ql-snow .ql-stroke.ql-fill {
		stroke: $dark-bg-color;
   }
   // slick
   .slick-slider .slick-prev::before,
   .slick-slider .slick-next::before,
   .slick-dots li button::before{
      color:$dark-bg-color !important;
   }
   .modal-content{
      background-color: $dark-bg !important;
   }
   .btn-outline-dark{
      border-color:$dark-bg-color;
   }
   .btn-outline-dark:hover{
      background-color:$dark-bg-color;
      border-color:$dark-bg-color;
   }
   .course-wrap .custom-bg,
	.course-wrap .custom-bg::after,
	.user-profile-wrapper .bottom-block,
	.cke_editable,
	.cke_1 .cke_bottom,
	.cke_button_label,
	.ql-snow .ql-picker,
	.chat-window,
	.cke_wysiwyg_frame,
	.cke_wysiwyg_div,
	.cke_editable,
	.ql-snow.ql-toolbar button,
	.ql-snow .ql-toolbar button,
	.ql-editor.ql-blank::before,
	.maintenance-wrap .maintenance-content,
	.ql-container,
	.ql-toolbar.ql-snow,
	.note-editor.note-frame .note-editing-area .note-editable,
	.ngx-datatable.material .datatable-footer .datatable-pager a,
	.ngx-datatable.material .datatable-footer,
	.ngx-datatable.material .datatable-header .datatable-header-cell,
	.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell,
	.navigation > ul li a.active-link,
	.dropdown-menu,
	.popover-header,
	.popover-body,
	.user-section,
	.chk-block,
	.chk-full-block,
	.card,
	.rating-progress-list,
	.inner-tab,
	.chat-wrapper .chk-block-title,
	.send-msg.bg-grey,
	.progress--circle::before,
	.main-header,
	.list-group-item,
	.datatable-header,
	.datatable-body,
	.datatable-footer,
	.card-header,
	.media-card,
	.post-detail,
	.about-author,
	.grid-box .grid-body,
	.search-block,
	.add-comment,
	.upload-file,
	.grid .media-card,
	.epion-customizer,
	.faq-wrap .nav-pills .nav-link.active,
	.chat-sidebar-pan {
		background-color: $dark-bg;
		color: $dark-bg-color;
		border-color: $dark-border-color;
	}
	.faq-wrap .tab-list ul li a:hover {
		color: $dark-bg-color !important;
		border-color: $dark-bg-color;
	}
	.maintenance-wrap .maintenance-content,
	.faq-wrap .nav-pills .nav-link.active {
		background-color: rgba($dark-bg, 0.95);
	}
	.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
		color: rgba($dark-bg-color, 0.26) !important;
	}
	div.author-card,
	.ecommerce-product-list-wrapper .bg-light {
		background-color: $dark-bg !important;
	}
	.chk-block-title,
	.list-item-border li,
	.table th,
	.table td,
	.nav-tabs,
	.nav-tabs-box .tab-content,
	.tab-content-bordered .tab-content,
	.tabs-left .nav-tabs,
	.tabs-right .nav-tabs,
	.tabs-left .nav-tabs .nav-link.active,
	.tabs-right .nav-tabs .nav-link.active,
	.tabs-below > .nav-tabs,
	.table-bordered,
	.user-profile-wrapper .bottom-block {
		border-color: $dark-border-color;
	}
	.inner-tab .list-group li,
	.settings-panel .nav-link {
		background-color: $dark-bg;
	}
	.chk-inbox-sidebar {
		.nav-tabs {
			.nav-item {
				border-color: $dark-border-color;
				&:last-child {
					border-color: $dark-border-color;
				}
			}
		}
	}
	.dropdown-divider,
	.cal-month-view .cal-cell-row:hover,
	.cal-month-view .cal-cell-row .cal-cell:hover,
	.cal-month-view .cal-cell.cal-has-events.cal-open,
	.ngx-datatable.material.cell-selection .datatable-body-cell:hover {
		background-color: $dark-border-color;
	}
	.nav-tabs .nav-link.active,
	.page-link,
	.input-group-addon,
	.card-wrap,
	.timeline-card,
	.settings-panel .chat-tabs > a,
	.system-setting ul li,
	.btn-light,
	.popover-title,
	.popover-content,
	.popover {
		background-color: $dark-bg-container;
		color: $dark-bg-color;
		border-color: $dark-border-color;
	}
	.chk-ui-heading h3,
	.vertical-list dd,
	.horizontal-list dd,
	.main-contents,
	.icon-box li,
	.form-group label,
	.blog-links a,
	.blog-meta a,
	pre,
	.action-icon li a {
		color: $dark-bg-color;
	}
	.chat-wrapper .chk-block-title,
	.user-contact-wrapper .card-action-link{
		background-color: $dark-bg !important;
		color: $dark-bg-color;
		border-color: $dark-border-color;
	}
	//timeline
	.timeline-card {
		box-shadow: 0 3px 0 $dark-bg-container;
	}
	//tables
	.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
	.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
		background-color: $dark-bg-container;
	}
	.table-striped tbody tr:nth-of-type(2n+1) {
		background-color: #3d3d3d;
   }
   .widget-table .email-id a, .widget-table .phone-no a,table a,table th, table td{
      color: $dark-bg-color;
   }
	.form-control {
		background-color: #3d3d3d;
		border-color: $dark-border-color;
		color: $dark-bg-color;
	}
	.ngx-datatable.material .datatable-body .datatable-row-left,
	.ngx-datatable.material .datatable-body .datatable-row-right,
	.ngx-datatable.material .datatable-header .datatable-row-left,
	.ngx-datatable.material .datatable-header .datatable-row-right {
		background-color: $dark-bg;
	}
	.horizontal-nav ul .link-item {
		color: $dark-bg-color;
	}
	.horizontal-nav {
		ul {
			.link-item {
				color: $dark-bg-color;
			}
		}
		.horizontal-menu {
			.child-menu,
			.mega-menu {
				background: $dark-bg;
			}
			> li > a {
				i {
					color: $dark-bg-color;
				}
				&:hover {
					background-color: $dark-bg;
				}
			}
			.mega-title h3 {
				color: $dark-bg-color;
			}
			> li.has-child::after {
				color: $dark-bg-color;
			}
		}
		.horizontal-menu .child-menu li::after {
			color: $dark-bg-color;
		}
	}
	//	search
	.search-form .form-control {
		background: transparent;
	}
	.mega-menu h3 a {
		color: $dark-bg-color;
	}
	.settings-panel .nav-link,
	.settings-panel .chat-tabs > a small {
		color: $dark-bg-color;
	}
	.settings-panel .chat-tabs > a:hover {
		background: $dark-bg;
	}
	.inner-tab {
		border-color: $dark-border-color;
	}
	.send-msg {
		border-color: $dark-border-color;
		background-color: $dark-bg !important;
	}
	.node-value {
		i,
		span {
			color: $dark-bg-color;
		}
	}
	.more-theme-style a {
		color: $dark-bg-color;
	}
	.inbox-wrapper {
		.bg-grey {
			background-color: $dark-bg-container;
		}
	}
	.chat-wrapper {
		.bg-grey {
			background-color: $dark-bg-container !important;
		}
	}
}