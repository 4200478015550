/*========== Ecommerce Product Detail ============*/
.ecommerce-product-wrapper, .ecommerce-product-detail {
	.media-card {
		padding: 20px;
		.thumbnail {
			margin: 0;
		}
		.media-body {
			padding: 0;
		}
	}
}
.gallery {
    a {
        @include border(1px solid, $input-border-color, top bottom left right);
        &:hover {
            border-color: $blue;
        }
    }
}
.ecommerce-product-wrapper {
	.card {
		display: inherit;
		text-align: center;
	}
}
/*========== Ecommerce order ============*/
.ecomm-order-wrapper {
	.count {
		font-size: 30px;
	}
	.legend {
		padding-top: 50px;
	}
}
.review-block {
  background-color: $gray-700;
  padding: 30px;
  h2 {
  	font-size: 50px;
  }
  p {
  	font-size: 18px;
  	i {
  		font-size: 24px;
  	}
  }
}
.rating-progress-list {
	.progress {
		margin: 0;
		border: none;
		background-color: $gray-300;
	}
	li {
		margin-bottom: 20px;
	}
	.progress-listing {
		padding: 0.5rem 0 0 3rem;
	}
}
/*=====Invoice v2 // invoice list====*/
.invoice-v2-wrap{
	.chk-block-title{
		border-bottom:0px;
	}
	table{
		border-top:1px solid #DEE4E8;
		border-bottom:1px solid #DEE4E8;
		th{
			vertical-align:middle;
			border:1px solid transparent;
			border-top:1px solid transparent;
		}
		td{
			border:1px solid transparent;
			vertical-align:middle;
		}	
	}
	.invoice-chkbox {
		.text-relative{
			position:relative;
			margin-bottom:0.3rem;
		}
		&.custom-control{
			padding:0px 10px;
		}
		.custom-control-label{
			vertical-align:text-top;
			position:static;
			&:after{
				left:0;
			}
			&:before{
				left:0;
			}	
		}
	}
}
.invoice-pagination,.contact-list-pagination,.usermanagelist-pagination{
	.page-item{
		.page-link{
			&:hover{
				transition: all 0.5s ease-in-out; 
				background-color: #DEE4E8 !important;
			}
		}
	}
}
/*========== Ecommerce product-cart ============*/
@media (max-width:600px){
   .ecommerce-product-cart-wrapper{
      .cart-img{
         min-width:100px;
      }
      .desc{
         min-width:200px;
      }
   }
}
.ecommerce-product-cart-wrapper{
   .form-control.form-field{
      min-width:110px;
      width:3rem;
   }
}