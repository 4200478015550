/*=========== 3.0 Core =============*/

@import "core/main-content";
@import "core/scaffolding";
@import "core/button";
@import "core/form-elements";
@import "core/progressbar";
@import "core/tabs";
@import "core/accordions";
@import "core/pagination";
@import "core/cards";
@import "core/typography";
@import "core/badge";
@import "core/dropdowns";
@import "core/social";
@import "core/table";
@import "core/ribbon";
@import "core/header";
@import "core/sidebar";
@import "core/preloader";
@import "core/dash-section";
@import "core/timeline";
@import "core/dark";
@import "core/rtl";